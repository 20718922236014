var start_el = 0;
var tags = false;
var adcategory = false;
var adtype = false;
var timerid;
$(document).ready(function(e) {

    $('#search_ads').on('input', function(e){
        e.preventDefault();
        var elem = $(this);
        var resultsContainer = $('.allPostsContainer');
        var value = elem.val();
        var categories = $('#search_ads_categories').val();
        var maintype = $('#search_ads_type').val();
        var tag = $('#search_ads_tag').val();
        if (value.length > 2) {
            if(elem.data("lastval") != value){
                elem.data("lastval", value);
                clearTimeout(timerid);
                timerid = setTimeout(function() {
                    $.get(search_ads, {'search_ads': elem.val(), 'tag': tag, 'categories': categories, 'maintype': maintype}, function(response){
                        if (response) {
                            resultsContainer.html(response);
                            initInfinitescroll(undefined);
                        }
                    });
                },600);
            };
        }
    });

    $('#search').on('input', function(e){
        e.preventDefault();
        var elem = $(this);
        var resultsContainer = $('#searchResultsContainer');
        resultsContainer.empty();
        if (elem.val().length >= 2) {
            $.post(search, {'_token':token, 'search': elem.val()}, function(response){
                resultsContainer.empty();
                resultsContainer.append(response);
            });
        }
    });

    $('#addPost').submit(function(e){
        //e.preventDefault();
        //$(this).submit();
        //var newPost = $('.postContainer').first();
        //console.log(newPost);
        //$('.allPostsContainer').prepend('<div class="postContainer">'+newPost.html()+'</div>');
    });

    $("#attachmentPicture").change(function() {
        readURL(this);
    });

    $('body').on('click', '.postContent .icon-close', function(e){
        e.preventDefault();
        var elem = $(this);

        $.post(deletePostUrl,{'_token': token, 'post_id': elem.attr('data-post')}, function(r){
            if (r == 'deleted') {
                elem.parent().parent().parent().parent().remove();
            }
        });
    });


    $('body').on('click', '.postComments .icon-close', function(e){
        e.preventDefault();
        var elem = $(this);
        $.post(deleteCommentUrl,{'_token': token, 'comment_id': elem.attr('data-comment'), 'post_id': elem.attr('data-post')}, function(r){
            if (r == 'deleted') {
                elem.parent().remove();
            }
        });
    });

    $('body').on('click', '.like', function(e){
        e.preventDefault();
        var elem = $(this);
        var post_id = elem.attr('data-post');
        $.post(likeUrl, {'_token': token, 'post_id':post_id}, function(response){
            if(response.status == 'like') {
                elem.addClass('active');
            }
            else if (response.status == 'no_like') {
                elem.removeClass('active');
            }
            else {
                alert('Tekkis viga!');
            }
        }, 'json');
    });

    $('body').on('click', '.share', function(e){
        e.preventDefault();
        var elem = $(this);
        var url = elem.attr('data-url');
        var post_id = elem.attr('data-post');
        FB.ui({
            method: 'share',
            href: url,
        }, function(response){
            if (response) {
                $.post(shareUrl, {'_token': token, 'post_id': post_id}, function(response){});
            }
        });
    });

    $('body').on('click', '.showAllComments', function(e){
        e.preventDefault();
        var elem = $(this);
        elem.parent().find('.postComments li').show();
        elem.hide();
    });

    $('body').on('keypress keydown', '.commentBox', function(event) {
        if (event.keyCode == 13 && event.shiftKey) {
            var content = this.value;
            var caret = getCaret(this);
            this.value = content.substring(0,caret)+"\n"+content.substring(carent,content.length-1);
            event.stopPropagation();
        }
        else if(event.keyCode == 13) {
            event.stopPropagation();
            event.preventDefault();
            var elem = $(this);
            var data = elem.parent().serialize();
            $.post(commentUrl, data, function(response){
                elem.parent().find('.error').hide();
                if (response.status == 'commented') {
                    elem.val('');
                    elem.parent().parent().parent().find('.postComments').prepend(response.msg);
                }
                else {
                    var error = '<ul class="error">';
                    $.each(response.msg, function(index, value){
                        error += '<li>'+value+'</li>';
                    });
                    error += '</ul>';
                    elem.parent().prepend(error);
                }
                console.log(1);
            }, 'json');
        }
    });

    $('body').tooltip({
        selector: '.likeCounter, .shareCounter',
        html: true,
        placement: 'bottom'
    });

    $('.chooseProfile').click(function(e){
        e.preventDefault();
        var elem = $(this);
        elem.parent().find('.profiles').toggleClass('show');
    });

    $('body').on('click', '.follow', function(e){
        e.preventDefault();
        var user_id = $(this).attr('data-user');
        var elem = $(".follow[data-user='"+user_id+"']");
        follow({'_token':token, 'user_id': elem.attr('data-user')}, function(response){
            if (response == 'follow') {
                elem
                    .removeClass('icon-add-circle icon-outlined-checked icon-close')
                    .addClass('icon-outlined-checked');
                elem.find('.followTxt')
                    .removeClass("hide")
                    .addClass("show");
                elem.find('.followStartTxt')
                    .removeClass('show')
                    .addClass('hide');
                elem.find('.followStopTxt')
                    .removeClass('show')
                    .addClass('hide');
            }
            else if (response == 'unfollow') {
                elem
                    .removeClass('icon-add-circle icon-outlined-checked icon-close')
                    .addClass('icon-add-circle');
                elem.find('.followTxt')
                    .removeClass("show")
                    .addClass("hide");
                elem.find('.followStartTxt')
                    .removeClass('hide')
                    .addClass('show');
                elem.find('.followStopTxt')
                    .removeClass('show')
                    .addClass('hide');
            }
            else {
                console.log('Tekkis viga!');
            }
        });
    });

    $('body').on('mouseover', '.follow.icon-outlined-checked', function(e){
        e.preventDefault();
        var elem = $(this);
        elem
            .removeClass('icon-outlined-checked')
            .addClass('icon-close');
        elem.find('.followTxt')
            .removeClass("show")
            .addClass("hide");
        elem.find('.followStopTxt')
            .removeClass("hide")
            .addClass("show");
    });

    $('body').on('mouseleave', '.follow.icon-close', function(e){
        e.preventDefault();
        var elem = $(this);
        elem
            .removeClass('icon-close')
            .addClass('icon-outlined-checked');
        elem.find('.followTxt')
            .removeClass("hide")
            .addClass("show");
        elem.find('.followStopTxt')
            .removeClass("show")
            .addClass("hide");
    });

    $('.notification.redB').click(function(e){
        e.preventDefault();
        var $this = $(this);
        $this.parent().parent().find('.notifications').toggleClass('open');
    });

    $('.datepicker').datepicker({
        dateFormat : 'yy-mm-dd',
        language: 'et'
    });

    $('.infobox').popover({
        trigger: "hover",
        container: 'body'
    });

	$('.menu-cont ul li').hover(function(e) {
        $(this).children('ul').stop().animate({width: 'toggle'});
    });

    if($("#ad_type").length) {
        var ad_type = $('#ad_type');
        var category = $('#change_category');
        var old_category = parseInt(category.data('oldvalue'));
        var breed = $('#breed_list');
        var old_breed = parseInt(breed.data('oldvalue'));
        var ad_deal = $('#ad_deal');

        $.post( getCategoriesUrl, { category_type: ad_type.val(), _token: token }, function(data) {
            category.empty().append(data);
            if (old_category > 0) {
                category.find('option[value="'+old_category+'"]').attr("selected","selected").change();
            }
        });

        $(document).delegate('#ad_type', 'change', function(e){

            ad_deal.empty();

            $('.hide-breed').slideUp();

            $.post( getCategoriesUrl, { category_type: ad_type.val(), _token: token }, function(data) {
                category.empty().append(data);
            });

            $.post( getBreedUrl, { category_id: category.val(), _token: token }, function(data) {
                if (data == 0) {
                    breed.empty().append('<option value=0>-</option>');
                }
                else {
                    breed.empty().append(data);
                    breed.val($('#selected_breed').val());
                }

                if (ad_type.val() == 5) {
                    ad_deal.parent().parent().slideUp();
                }
                else {
                    ad_deal.parent().parent().slideDown();
                }
                if (old_breed > 0) {
                    breed.find('option[value="'+old_breed+'"]').attr("selected","selected").change();
                }
                if (data != 0) {
                    $('.hide-breed').slideDown();
                }
            });

            $.each(ad_deals, function(k, v){
                if (ad_type.val() == 1 || ad_type.val() == 6) {
                    if (v.type == 1 || v.type == 2 || v.type == 3 || v.type == 6) {
                        ad_deal.append('<option value="' + v.type + '">' + v.name + '</option>');
                    }
                }
                else if (ad_type.val() == 2) {
                    if (v.type == 2) {
                        ad_deal.append('<option value="' + v.type + '">' + v.name + '</option>');
                    }
                }
                else if (ad_type.val() == 3) {
                    if (v.type == 2) {
                        ad_deal.append('<option value="' + v.type + '">' + v.name + '</option>');
                    }
                }
                else if (ad_type.val() == 4) {
                    if (v.type == 1 || v.type == 2 || v.type == 6) {
                        ad_deal.append('<option value="' + v.type + '">' + v.name + '</option>');
                    }
                }
                else if (ad_type.val() == 5) {
                    if (v.type == 7) {
                        ad_deal.append('<option value="' + v.type + '">' + v.name + '</option>');
                    }
                }
                else if (ad_type.val() == 7) {
                    if (v.type == 4 || v.type == 5) {
                        ad_deal.append('<option value="' + v.type + '">' + v.name + '</option>');
                    }
                }
                else {
                    ad_deal.append('<option value="' + v.type + '">' + v.name + '</option>');
                }
            });

        });
    }

    $('body').on('change', '#change_category', function(e){
        var breed = $('#breed_list');
        var old_breed = parseInt(breed.data('oldvalue'));
        $('.hide-breed').slideUp();
        $.post( getBreedUrl, { category_id: $('#change_category').val(), _token: token }, function(data) {
            if (data == 0) {
                breed.empty().append('<option value=0>-</option>');
            }
            else {
                $('#breed_list').empty().append(data);
            }
            if (old_breed > 0) {
                breed.find('option[value="'+old_breed+'"]').attr("selected","selected").change();
            }
            if (data != 0) {
                $('.hide-breed').slideDown();
            }
        });
    });

    $('body').on('change', '#coverImage', function(e){
        $("#coverImageForm").submit();
    });

    $(document).delegate('#profile_pic', 'change', function(e){
        $( "#profile_form" ).submit();
    });

    $(".fancybox").fancybox({
        helpers	: {
            title	: {
                type: 'outside'
            },
            thumbs	: true
        }
    });

    $( "#sortable" ).sortable({
        forcePlaceholderSize: true,
        placeholder: "col-xs-3 myplace",
        cursorAt: { top: 0, left: 0 }
    });

    $( "#sortable" ).disableSelection();

    $('#sortorder').val($( "#sortable" ).sortable( "serialize", { key: "sort" } ));

    $( "#sortable" ).sortable({
        stop: function( event, ui ) {
            $('#sortorder').val($( "#sortable" ).sortable( "serialize", { key: "sort" } ));
        }
    });

    equalheight('.news-6, .news-3, .myads-one .thumbnail, .itemContainer');

    if($('input[name="deal"]').is(':checked')){
        $('input[name="ad_price"]').attr('disabled', true);
    }else{
        $('input[name="ad_price"]').attr('disabled', false);
    }

    $(document).delegate('input[name="deal"]', 'change', function(e){
        if($('input[name="deal"]').is(':checked')){
            $('input[name="ad_price"]').attr('disabled', true);
        }else{
            $('input[name="ad_price"]').attr('disabled', false);
        }
    });
	
	
	$(window).resize(function(e) {
        changeMenuHeight();
    });
	
	$("#filtering").select2({
        language: "et",
		placeholder: lang.searcbByTag,
        minimumResultsForSearch: 1
	});

    if($('select[name="contacts"]').val() == 'new') {
        $('.hide-contact').slideDown();
    }

    $('select[name="contacts"]').change(function(){
        if($('select[name="contacts"]').val() == 'new'){
            $('.hide-contact').slideDown();
        }
        else{
            $('.hide-contact').slideUp();
        }
    });

    $("#ad_tags").select2({
        language: "et",
        tags: true,
        tokenSeparators: [',', ' '],
        placeholder: lang.addTags,
        maximumSelectionLength: 30
    });


    $("#ad_procedures").select2({
        language: "et",
        tags: false,
        tokenSeparators: [','],
        placeholder: lang.addProcedures
    });

	setTimeout(function(){
		$('.bottom-line').css('border-width', '15px '+$('.price-tag').outerWidth()+'px 0 0');
        $('.price-tag').css('left', 0).css('top', 0);
	}, 100);

    changeMenuHeight();

    setInterval(
        function() {
            changeMenuHeight();
            equalheight('.news-6, .news-3, .myads-one .thumbnail, .itemContainer');
        }, 500
    );

    if ($('#frontpage').length) {
        initInfinitescroll(1);
    } else {
        initInfinitescroll(undefined);
    }
});

var equalheight = function(container) {
    var currentTallest = 0,
    currentRowStart = 0,
    rowDivs = new Array(),
    jQueryel,
    topPosition = 0;
    jQuery(container).each(function() {
        jQueryel = jQuery(this);
        jQuery(jQueryel).height('auto');
        var topPostion = jQueryel.position().top;

        if (currentRowStart != topPostion) {
            for (var currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
                rowDivs[currentDiv].height(currentTallest);
            }
            rowDivs.length = 0; // empty the array
            currentRowStart = topPostion;
            currentTallest = jQueryel.height();
            rowDivs.push(jQueryel);
        } else {
            rowDivs.push(jQueryel);
            currentTallest = (currentTallest < jQueryel.height()) ? (jQueryel.height()) : (currentTallest);
        }
        for (var currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
            rowDivs[currentDiv].height(currentTallest);
        }
    });
};

function changeMenuHeight(){
    $('.menu-cont').height($('.ads').height());
}

function cutString(s, n){
    var cut= s.indexOf(' ', n);
    if(cut== -1) return s;
    return s.substring(0, cut)
}
function getCaret(el) {
    if (el.selectionStart) {
        return el.selectionStart;
    } else if (document.selection) {
        el.focus();

        var r = document.selection.createRange();
        if (r == null) {
            return 0;
        }

        var re = el.createTextRange(),
            rc = re.duplicate();
        re.moveToBookmark(r.getBookmark());
        rc.setEndPoint('EndToStart', re);

        return rc.text.length;
    }
    return 0;
}
function readURL(input) {
    if (typeof (FileReader) != "undefined") {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            var imgPath = input.files[0];
            var extn = imgPath.name.substring(imgPath.name.lastIndexOf('.') + 1).toLowerCase();
            if (extn == "gif" || extn == "png" || extn == "jpg" || extn == "jpeg") {
                reader.onload = function (e) {
                    var imgContainer = $('#attachmentPreviewContainer');
                    var img = $('<img id="attachmentPicturePreview" class="attachmentPreview img-responsive img-thumbnail">');
                    imgContainer.html('');
                    img.attr('src', e.target.result);
                    img.appendTo(imgContainer);
                };
                reader.readAsDataURL(imgPath);
            }
        }
    }
}

function follow(user, callback) {
    $.post(followUrl, user, function(response) {
        callback(response);
    });
}

function initInfinitescroll(maxPage) {
    $('.allPostsContainer .row:first').infinitescroll({
        navSelector  	: ".paginationContainer .pagination",
        nextSelector 	: ".paginationContainer .pagination li a:first",
        itemSelector 	: ".item",
        debug		 	: true,
        dataType	 	: 'html',
        maxPage         : maxPage,
        loading: {
            finished: undefined,
            finishedMsg: '',
            img: homeUrl+'/img/loading.gif',
            msg: null,
            msgText: '',
            selector: null,
            speed: 'fast',
            start: undefined
        },
        state: {
            isDestroyed: false,
            isDone: false,
            isDuringAjax: false,
            isInvalidPage: false,
            isPaused: false,
            currPage: 1
        }
    });
}